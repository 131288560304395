<template>
  <div class="paypalsub">
    <div class="form-group" v-if="formError">
      <div class="error-container">
        <p class="error">{{ formError }}</p>
      </div>
    </div>
    <button @click="paymentInit()">
      <span v-if="!loader">PAYPAL</span>
      <Loading v-else></Loading>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import googleAnalytics from "@/mixins/googleAnalytics.js";
import facebookPixelAnalytics from "@/mixins/facebookPixelAnalytics.js";
import Utility from "@/mixins/Utility.js";
export default {
  props: {
    gatewaydetail: {
      type: String,
    },
    paymentDetail: {
      type: Object,
    },
  },
  data() {
    return {
      loader: false,
      formError: null,
			planAndDiscount: null
    };
  },
  created() {
		this.planAndDiscount = this.appConfig.planDiscount;
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 2000);
      }
    },
  },
  methods: {
    ...mapActions(["paymentInitiation"]),
    ...mapGetters(["appConfig"]),
    paymentInit() {
      let currentDomain = window.location.origin;
			let payload = {
        gatewayid: this.gatewaydetail,
        params: {
          amount: this.paymentDetail.detail.amount,
          currency: this.paymentDetail.detail.currency,
          devicetype: this.appConfig.planPrice[0].devicetype,
          transactionpurpose: this.paymentDetail.transactionPurpose,
          transactionmode: this.appConfig.planPrice[0].transactionmode,
          availabilityid: this.appConfig.planPrice[0].availabilityid,
          planid: this.paymentDetail.detail.planid,
        },
      };


      payload.params.client_return_url = currentDomain + "/profile/plans" + "?" + "planid=" + this.paymentDetail.detail.planid;

      this.loader = true;
      this.paymentInitiation(payload)
        .then((response) => {
          this.loader = false;
          if (!response.data.reason) {
            const browser = this.getPlatformType();

            const theSource = localStorage.getItem("payment_source");

            let paymentDetailsForGA = {
              free_trial: this.getFreeTrial(),
              payment_mode: this.getPaymentMode(response.data.referencedata.gwprovider),
              plan_id: this.paymentDetail.detail.planid,
              plan_name: this.paymentDetail.detail.planname,
              payment_id: response.data.referencedata.paymentid,
              source: theSource,
              object_id: "",
              object_name: "",
              platform: this.getPlatform(),
              platform_type: browser.browser,
            };

        
            window.open(response.data.referencedata.approveUrl, "_self");

            try {
                 // Google Analytics Event
              this.subscriptionStarted(paymentDetailsForGA);
              // Facebook analytics Event
              this.faceBookInitateCheckoutEvent(paymentDetailsForGA);
            } catch (e) {console.log(e)}

           } else {
            this.formError = response.data.reason;
          }
        })
        .catch((error) => console.log(error));
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },
  mixins: [Utility, googleAnalytics, facebookPixelAnalytics],
};
</script>

<style lang="scss">
@import "@/sass/_components.scss";
@import "./paypalSub.scss"
</style>
